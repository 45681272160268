import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKalina as author } from 'data/authors';
import img from 'img/blog/livesession-summary.png';

import img1 from 'img/blog/statistics.svg';
import img2 from 'img/blog/appdashboard.png';
import img3 from 'img/blog/create-segment.png';
import img4 from 'img/blog/engagement.png';
import img5 from 'img/blog/g2-high-performer.png';
import img6 from 'img/blog/awards_2.png';
import img7 from 'img/blog/postbetterux.png';
import img8 from 'img/blog/userexp.png';
import img9 from 'img/blog/tipsmanagers.png';
import img10 from 'img/blog/abtests.png';
import img11 from 'img/blog/notifications.svg';
import img12 from 'img/blog/autoplay.png';
import img13 from 'img/blog/team.png';

import video from 'img/blog/inspect_mode_gif.webm';
import video2 from 'img/blog/intercom_integration.webm';

const Content = () => {
  return (
    <Post>
      <p>2019 was quite a ride.</p>
      <p>Thank you for being with us throughout the year!</p>
      <p>
        We’ve been working hard to develop a session recording app to meet your needs. And we’re not
        stopping there!
      </p>
      <p>
        Our mission is to create a product that allows you to get valuable insights as fast as
        possible. We’re not just working on a qualitative analytics app. The goal is to create an
        app that makes analyzing session recordings <strong>faster and more effective.</strong>
      </p>
      <p>
        Ultimately, you won’t have to spend long hours watching all the session replays. Instead,
        you can select your filters in a few clicks and get actionable insights right away.
      </p>
      <p>Enough talking, what about action? Here’s what we’ve been up to in 2019:</p>
      <h2 id="a-few-statistics">A few statistics</h2>
      <picture>
        <source srcSet="/img/statistics-mobile.svg" media="(max-width: 800px)" />
        <img src={img1} title="Statistics" alt="stats" />
      </picture>

      <h2 id="new-releases-in-2019">New releases in 2019</h2>
      <p>
        We’ve introduced dozens of new features in 2019. Some of the most important ones included:
      </p>
      <h3 id="dashboard-segmentation">Dashboard segmentation</h3>
      <p>
        <img src={img2} alt="dashboard" title="LiveSession Dashboard" />
      </p>
      <p>
        Filtering made easier: you can use filters and{' '}
        <a
          href="/blog/dashboard-filtering-and-segmentation/"
          target="_blank"
          rel="noopener noreferrer"
        >
          track different segments
        </a>{' '}
        right on the dashboard.
      </p>
      <h3 id="segment-sharing">Segment sharing</h3>
      <p>
        <img src={img3} alt="segmentation" title="Segmentation sharing" />
      </p>
      <p>
        Share segments with your colleagues and make qualitative analytics more effective.{' '}
        <a href="/blog/segment-sharing/" target="_blank" rel="noopener noreferrer">
          Segment sharing
        </a>{' '}
        will facilitate teamwork in your company.
      </p>
      <h3 id="engagement-score">Engagement score</h3>
      <p>
        <img src={img4} alt="engagement" title="Engagement score" />
      </p>
      <p>
        A feature that makes us especially proud.{' '}
        <a href="/blog/engagement-score/" target="_blank" rel="noopener noreferrer">
          Engagement score
        </a>{' '}
        will save you lots of time! Each recording gets a score based on different indicators on
        user activity. You don’t have to watch hours of boring material to find what you’re looking
        for. With LiveSession’s engagement score, you’re able to find the most interesting sessions
        right away.
      </p>
      <h3 id="inspect-mode">Inspect mode</h3>
      <video width="100%" height="100%" autoPlay muted loop>
        <source src={video} type="video/webm" />
      </video>

      <p>
        We make it easier to track how people interact with particular elements on your website. You
        find the right recordings very quickly with{' '}
        <a href="/blog/inspect-mode/" target="_blank" rel="noopener noreferrer">
          inspect mode
        </a>
        .
      </p>
      <h2 id="awards-and-recognition">Awards and recognition</h2>
      <p>
        We’re extremely happy to see that LiveSession is gaining recognition in the SaaS industry.
        In 2019, we’ve received the High Performer badge from G2 – twice!{' '}
        <a
          href="https://www.g2.com/products/livesession/reviews"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          G2
        </a>{' '}
        is a software review website, which means that the recognition is based on insights from
        real LiveSession clients. Thank you for your valuable feedback!
      </p>
      <p>
        <img src={img5} alt="award" title="Award High Performer" />
      </p>
      <p>
        <a
          href="https://www.g2.com/products/livesession/reviews"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="link-button"
        >
          Leave us a G2 review
        </a>
      </p>
      <p>
        What’s more, we’ve received the <strong>Premium Usability Honor</strong> from{' '}
        <a
          href="https://reviews.financesonline.com/p/livesession/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          FinancesOnline
        </a>
        , another prominent software review platform. LiveSession was praised for “total visibility
        and transparency”, as well as the ability to come up with “highly actionable insights”.
      </p>
      <p>
        We’ve also been named a Rising Star on the session replay market. This makes us motivated to
        work even harder!
      </p>
      <p>
        <img src={img6} alt="award" title="Awards from FinancesOnline" />
      </p>
      <h2 id="best-blog-posts-of-2019">Best blog posts of 2019</h2>
      <p>
        Creating world-class session replay software is one thing. We’re also striving to become a
        knowledge hub for user experience research, conversion rate optimization and all things
        related to session replays.
      </p>
      <p>
        In 2019, our posts were featured in acclaimed UX publications, including{' '}
        <a
          href="https://uxdesign.cc/7-psychological-principles-for-better-ux-3ab1620b123"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          UX Collective
        </a>{' '}
        (uxdesign.cc), a popular UX magazine, and the{' '}
        <a
          href="https://blog.balsamiq.com/ux-ui-links-december-2019/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Balsamiq
        </a>{' '}
        blog:
      </p>
      <p>
        <img src={img7} alt="best posts" title="Best blog posts" />
      </p>
      <p>Our top 3 blog posts in 2019 were:</p>
      <h3 id="7-psychological-principles-for-better-ux">
        <a
          href="/blog/7-psychological-principles-for-better-ux/"
          target="_blank"
          rel="noopener noreferrer"
        >
          7 Psychological Principles for Better UX
        </a>
      </h3>
      <p>
        <img src={img8} alt="better ux" title="User experience" />
      </p>
      <p>
        An introduction to psychology for user experience professionals. Learn how to use
        psychological principles to improve the quality of your work.
      </p>
      <h3 id="tips-for-new-product-managers-with-expert-quotes-">
        <a
          href="/blog/tips-for-new-product-managers-with-expert-quotes/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tips for new product managers (with expert quotes)
        </a>
      </h3>
      <p>
        <img src={img9} alt="tips for managers" title="Best tips for new product managers" />
      </p>
      <p>
        New in the product management business? Read actionable tips from real product managers. We
        talked to professionals from{' '}
        <a href="https://www.tagvenue.com/" target="_blank" rel="noopener noreferrer nofollow">
          Tagvenue
        </a>
        ,{' '}
        <a href="https://www.datafeedwatch.com/" target="_blank" rel="noopener noreferrer nofollow">
          DataFeedWatch
        </a>
        ,{' '}
        <a href="https://mapp.com/" target="_blank" rel="noopener noreferrer nofollow">
          Mapp Digital
        </a>{' '}
        and{' '}
        <a href="https://onpagechamp.com/" target="_blank" rel="noopener noreferrer nofollow">
          OnPage Champ
        </a>
        .
      </p>
      <h3 id="5-tips-for-better-a-b-tests">
        <a href="/blog/5-tips-for-better-a-b-tests/">5 Tips for Better A/B Tests</a>
      </h3>
      <p>
        <img src={img10} alt="blog-ab tests" title="Tips for A/B tests" />
      </p>
      <p>
        Learn how to improve your A/B tests and get more relevant insights. We’re listing some of
        our best tips in this blog post.
      </p>
      <h2 id="what-comes-next-">What comes next?</h2>
      <p>
        2019 was a year of rapid growth – and LiveSession is just getting started. We receive plenty
        of suggestions from our clients and we do our best to meet the demand.
      </p>
      <p>
        Here’s what’s included in our <strong>2020 roadmap:</strong>
      </p>
      <h3 id="segment-notifications">Segment notifications</h3>
      <p>
        <img src={img11} title="Notifications" alt="notifications" />
      </p>
      <p>
        Soon LiveSession will be able to send a summary of segment activities directly to your
        email. You won’t even have to log in to the app. Instead, you’ll receive a clear,
        automatically generated report– a huge time saver, isn’t it?
      </p>
      <h3 id="session-autoplay">Session autoplay</h3>
      <p>
        <img src={img12} alt="player" title="Autoplay session" />
      </p>
      <p>
        You’ll be able to watch all the sessions from one segment in one go. When one playback ends,
        the next session will start automatically.
      </p>
      <h3 id="more-integrations-coming-soon">More integrations coming soon</h3>
      <video width="100%" height="100%" autoPlay muted loop className="smaller-video">
        <source src={video2} type="video/webm" />
      </video>

      <p>
        We’re currently working on extended{' '}
        <strong>integrations for Intercom, Segment and Sentry</strong>– to name just a few! If
        you’ve got an idea for an integration, don’t hesitate to let us know via Intercom. All you
        need to do is click the blue icon in the bottom right corner and send us a message. We’re
        more than happy to hear your thoughts.
      </p>
      <p>Thank you for an exciting 2019 and here’s to an even better 2020!</p>
      <p>
        <img src={img13} alt="our team" title="It&#39;s us!" />
      </p>
      <p>
        And if you’re not with us yet, you can{' '}
        <a href="/signup/" target="_blank" rel="noopener noreferrer">
          sign up
        </a>{' '}
        for for a free LiveSession trial right away. No credit card is required!
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'LiveSession 2019 Summary',
  url: '/blog/livesession-2019-summary/',
  description: `We’ve been working hard to develop a session recording app to meet your needs.
  And we’re not stopping there!`,
  author,
  img,
  imgSocial: img,
  date: '2020-01-30',
  category: '',
  group: 'Articles',
  timeToRead: 4,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
